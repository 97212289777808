import React, { Fragment, useEffect } from "react";
import "../Service3/ServiceStyles.css";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import ContactForm from "../../Components/ContactForm/ContactForm";
import Grid from "@mui/material/Grid2";
import { Box, Typography, Chip } from "@mui/material";
import { List, ListItem, ListItemDecorator, ListItemContent, ListItemButton, ListDivider } from "@mui/joy";
import HeroImg from '../../Assets/HeroImg.png'
import Img1 from "../../Assets/webservice1.jpg";
import Img2 from "../../Assets/Rectangle 60.png";
import Img3 from "../../Assets/Group 9.png";
import Img4 from "../../Assets/Group 10.png";
import Img5 from "../../Assets/Group 11.png";
import Img6 from "../../Assets/Group 12.png";
import Img7 from "../../Assets/Group 13.png";
import Img8 from "../../Assets/Group 14.png";
import Img9 from "../../Assets/Group 15.png";
import Img10 from "../../Assets/Group 16.png";
import Img11 from "../../Assets/Group 17.png";
import Icon1 from "../../Assets/coding.png";
import Icon2 from "../../Assets/web-design.png"
import Icon3 from "../../Assets/network-security.png"
import Icon4 from "../../Assets/server.png"
import { useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";


const reasons = [
  {
    id: 1,
    label: "Tailored Solutions",
    description:
      "We don’t believe in a one-size-fits-all approach. Every website we build is unique and customized to match your business goals.",
    icon: Img3,
    alt: 'Point 1'
  },
  {
    id: 2,
    label: "Cutting-Edge Technologies",
    description:
      "We leverage the latest frameworks and platforms such as React, Angular, and Laravel to ensure your site is fast, secure, and scalable.",
    icon: Img4,
    alt: 'Point 2'
  },
  {
    id: 3,
    label: "SEO Friendly",
    description:
      " Our websites are built with SEO best practices in mind, helping you rank higher on search engines.",
    icon: Img5,
    alt: 'Point 3'
  },
  {
    id: 4,
    label: "Security",
    description:
      "With robust security measures, we ensure that your website is protected from cyber threats.",
    icon: Img6,
    alt: 'Point 4'
  },
  {
    id: 5,
    label: "Cross-Browser Compatibility",
    description:
      "Your website will function smoothly across all major browsers including Chrome, Firefox, Safari, and Edge.",
    icon: Img7,
    alt: 'Point 5'
  },
];

export default function Services() {

  const navigate = useNavigate()

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return (
    <Fragment>
      <Navbar />

      <Grid container>
        <Grid //HERO SECTION
          size={{ xs: 12, md: 12 }}
          id="web-hero-section"
          sx={{ backgroundColor: "hsla(0, 0%, 0%, 0.521)" }}
        >
          <Box className="webServiceHeroContainer">
            <Box className="webServiceOverlay">
              <Box className="webServiceHeroImgContainer">
                <img
                    src={HeroImg}
                    height={300} width={'100%'}
                    alt="backgroundImage" 
                    />
              </Box>
              <Box className="webServiceHeroContentContainer">
                <Box className="webServiceHeroContentInnerContainer">
                  <Fade cascade triggerOnce="true">
                    <Typography
                      variant="h3"
                      fontWeight={"bold"}
                      fontFamily={"open sans"}
                      mt={1}
                      color="white"
                      // sx={{ fontSize: { lg: 66, md: 50, xs: 15 } }}
                    >
                      Web Development
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      fontFamily={"open sans"}
                      mt={2}
                      mx={1}
                      color="white"
                      // sx={{ fontSize: { sm: 15, xs: 10 } }}
                    >
                      Innovative Strategies To Transform Your Business
                    </Typography>
                  </Fade>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Box
          sx={{
            // backgroundColor: "red", 
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width:'85%', maxWidth:'1536px' }}>
          <Grid container spacing={2} >
            <Grid size={{ xs: 12, lg: 3 }}
              sx={{
                width: "100%",
              }}
            >
              <Box sx={{
                backgroundColor: "#f7f8f9",
                width: "100%",
                padding: "4% 2%",
                mt:'2rem'
              }}>
              <Typography
                              variant="h6"
                              fontWeight={"bold"}
                              fontFamily={"open sans"}
                              mx={0}
                              sx={{ marginBottom: "0px" }}
                            >
                              Services
                            </Typography>
                  <List inset="gutter" size="sm"  sx={{
                        "--ListItem-paddingY": "10px"
                      }}>
                  <ListItem>
                  <ListItemButton color="neutral" onClick={() => { navigate('/web-development') }}>
                    <ListItemDecorator sx={{margin:'0px'}}><img src={Icon1} height={30} width={30} alt="icon1" /></ListItemDecorator>

                    <ListItemContent >Web Development</ListItemContent>
                  </ListItemButton >
                  </ListItem>
                  <ListDivider />
                  <ListItem>
                  <ListItemButton color="neutral" onClick={() => { navigate('/uiux-designing') }}>
                    <ListItemDecorator sx={{margin:'0px'}}><img src={Icon2} height={30} width={30} alt="icon1" /></ListItemDecorator>
                    <ListItemContent >UI/UX Designing</ListItemContent>
                  </ListItemButton>
                  </ListItem>
                  <ListDivider />
                  <ListItem>
                  <ListItemButton color="neutral" onClick={() => { navigate('/cloud-solution') }}>
                    <ListItemDecorator sx={{margin:'0px'}}><img src={Icon4} height={30} width={30} alt="icon1" /></ListItemDecorator>
                    <ListItemContent>Cloud and Solutions Architecture</ListItemContent>
                  </ListItemButton>
                  </ListItem>
                  <ListDivider />
                  <ListItem>
                  <ListItemButton color="neutral" onClick={() => { navigate('/network-solution') }}>
                    <ListItemDecorator sx={{margin:'0px'}}><img src={Icon3} height={30} width={30} alt="icon1" /></ListItemDecorator>
                    <ListItemContent>IT & Networking Solutions</ListItemContent>
                  </ListItemButton>
                  </ListItem>
                </List>
                </Box>
            </Grid>
            <Grid size={{ xs: 12, lg: 9 }} >
              <Grid container>
             
                <Grid //INTRO SECTION
                  size={{ xs: 12, md: 12 }}
                >
                  <Box className="webServiceMainContainer">
                    <Box className="webServiceSecondaryContainer">
                      <Grid container>
                        <Grid size={{ xs: 12, md: 12 }}>
                          <Box className="webServiceIntroImageContainer">
                            <img
                              src={Img1}
                              loading="lazy"
                              alt="design"
                              className="webServiceIntroImage"
                            />
                          </Box>
                        </Grid>
                        <Grid size={{ xs: 12, md: 12 }}>
                          <Box className="webServiceIntroTextContainer">
                          <Chip
                            label="INNOVATIVE WEBSITES FOR A DIGITAL FIRST-WORLD"
                            className="homeChip"
                            sx={{ borderRadius: "5px", padding: "4px 0px 4px 0px", height: 'auto',
                              '& .MuiChip-label': {
                                display: 'block',
                                whiteSpace: 'normal',
                              },  }}
                          />
                            {/* <Typography
                              variant="subtitle2"
                              fontWeight={"bold"}
                              fontFamily={"open sans"}
                              align="left"
                              sx={{ color: "#b19478", marginBottom: "10px" }}
                            >
                              Innovative Websites for a Digital-First World
                            </Typography> */}
                            <Typography
                              variant="h5"
                              fontWeight={"bold"}
                              fontFamily={"open sans"}
                              mx={0}
                              align="left"
                              sx={{ marginBottom: "10px" }}
                            >
                              Building Future-Ready Websites That Captivate and
                              Convert
                            </Typography>
                            <Typography
                              variant="body2"
                              fontFamily={"open sans"}
                              color="#252525"
                              align="left"
                            >
                              In today's fast-paced digital environment, having a
                              website that not only functions well but also offers
                              a seamless user experience is critical for your
                              business’s success. At ClovVista Consulting, we
                              deliver tailored web development solutions that
                              focus on performance, scalability, and user-centric
                              design. 
                            </Typography>
                            <Typography
                              variant="body2"
                              fontFamily={"open sans"}
                              color="#252525"
                              align="left" mt={2}
                            >
                              Our team of skilled developers and designers
                              ensure that your website looks visually appealing
                              and performs optimally on all devices.
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    {/* <Box sx={{ width: "100%", maxWidth: "1536px" }}>
                      <Grid container>
                        <Grid size={{ xs: 12, md: 12 }}>
                          <Box
                            className="webServiceScrollingContainer"
                            sx={{
                              display: {
                                xs: "none",
                                sm: "none",
                                md: "none",
                                lg: "flex",
                              },
                            }}
                          >
                            {industries.map((index) => {
                              return (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <CodeIcon fontSize="small" />
                                  <Typography
                                    variant="h5"
                                    fontWeight={"900"}
                                    color="#EAEAEA"
                                    mx={2}
                                    sx={{ backgroundColor: "none" }}
                                  >
                                    {index.label}
                                  </Typography>
                                </Box>
                              );
                            })}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box> */}
                  </Box>
                </Grid>

                <Grid //WHY CHOOSE US SECTION
                  size={{ xs: 12, md: 12 }}  sx={{ backgroundColor: "#e7f5ef" }}
                >
                  <Box className="webServiceMainContainer" >
                    <Box className="webServiceSecondaryContainer">
                      <Grid container>
                        <Grid size={{ xs: 12, md: 12 }}>
                        <Chip
                            label="WHY CHOOSE US"
                            className="homeChip"
                            sx={{ borderRadius: "5px", padding: "0px" }}
                          />
                           
                            <Typography
                              variant="h5"
                              fontWeight={"bold"}
                              fontFamily={"open sans"}
                              mx={0}
                              sx={{ marginBottom: "0px" }}
                            >
                              Save Time and Effort With Us
                            </Typography>
                        </Grid>
                        <Grid size={{ xs: 12, md: 7 }}>
                          <Box className="webServiceChooseContainer">
                          
                            <Box className="webServiceLeftContainer">
                              {reasons.map((index) => {
                                return (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "flex-start",
                                      alignItems: "flex-start",
                                      marginTop: "1rem",
                                    }}
                                  >
                                    <img
                                      src={index.icon}
                                      height={30}
                                      width={30}
                                      alt={index.alt}
                                      loading="lazy"
                                    />
                                    <Typography
                                      variant="h6"
                                      mx={3}
                                      fontWeight={"bold"}
                                    >
                                      {index.label}
                                      <Typography variant="subtitle2">
                                        {index.description}
                                      </Typography>
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </Box>
                          </Box>
                        </Grid>
                        <Grid size={{ xs: 12, md: 5 }}>
                          <Box className="webServiceRightContainer">
                            <img
                              src={Img2}
                              loading="lazy"
                              class="img-fluid"
                              alt="backgroundImage"
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid> 

                <Grid //PROCESS SECTION
                  size={{ xs: 12, md: 12 }}
                >
                  <Box
                    className="webServiceMainContainer"
                    // sx={{ backgroundColor: "#e7f5ef" }}
                  >
                    <Box className="webServiceSecondaryContainer">
                      <Grid container>
                        <Grid size={{ xs: 12, md: 12 }}>
                          <Box className="webServiceProcessTextContainer">
                          <Chip
                  label="WHAT WE DO"
                  className="homeChip"
                  sx={{ borderRadius: "5px", padding: "0px" }}
                />
                            {/* <Typography
                              align="center"
                              variant="subtitle2"
                              fontWeight={"bold"}
                              fontFamily={"open sans"}
                              sx={{ color: "#b19478", marginBottom: "10px" }}
                            >
                              What We Do
                            </Typography> */}
                            <Typography
                              // align="center"
                              variant="h5"
                              fontWeight={"bold"}
                              fontFamily={"open sans"}
                              mx={0}
                              sx={{ marginBottom: "0px" }}
                            >
                              Check Out Our Work Process
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid size={{ xs: 12, md: 12 }}>
                          <Box className="webServiceStepperContainer">
                            <Grid container spacing={4}>
                              <Grid size={{ xs: 12, md: 3 }}>
                                <Box className="webServiceStepContainer">
                                  <Box
                                    className="webServiceStepCircle1"
                                    sx={{ height: 200, width: 200 }}
                                  >
                                    <img
                                      src={Img8}
                                      loading="lazy"
                                      class="img-fluid"
                                      alt="Step1"
                                    />
                                  </Box>
                                </Box>
                                <Typography
                                  variant="body1"  fontFamily={"open sans"}
                                  fontWeight={"900"}
                                  mt={1}
                                >
                                  Requirement Gathering
                                </Typography>
                                <Typography variant="caption"  fontFamily={"open sans"} color="#252525" mt={1}>
                                  We understand your business needs and goals to
                                  create a blueprint.
                                </Typography>
                              </Grid>
                              <Grid size={{ xs: 12, md: 3 }}>
                                <Box className="webServiceStepContainer">
                                  <Box
                                    className="webServiceStepCircle1"
                                    sx={{ height: 200, width: 200 }}
                                  >
                                    <img
                                      src={Img9}
                                      loading="lazy"
                                      class="img-fluid"
                                      alt="Step2"
                                    />
                                  </Box>
                                </Box>
                                <Typography  fontFamily={"open sans"}
                                  variant="body1"
                                  fontWeight={"900"}
                                  mt={1}
                                >
                                  Design & Prototyping
                                </Typography>
                                <Typography variant="caption"  fontFamily={"open sans"} color="#252525" mt={1}>
                                  Our designers provide wireframes and mockups for
                                  your approval before development.
                                </Typography>
                              </Grid>
                              <Grid size={{ xs: 12, md: 3 }}>
                                <Box className="webServiceStepContainer">
                                  <Box
                                    className="webServiceStepCircle1"
                                    sx={{ height: 200, width: 200 }}
                                  >
                                    <img
                                      src={Img10}
                                      loading="lazy"
                                      class="img-fluid"
                                      alt="Step3"
                                    />
                                  </Box>
                                </Box>
                                <Typography
                                  variant="body1"  fontFamily={"open sans"}
                                  fontWeight={"900"}
                                  mt={1}
                                >
                                  Development
                                </Typography>
                                <Typography variant="caption"  fontFamily={"open sans"} color="#252525" mt={1}>
                                  Using the latest technologies, our team builds
                                  the site, ensuring performance and
                                  responsiveness.
                                </Typography>
                              </Grid>
                              <Grid size={{ xs: 12, md: 3 }}>
                                <Box className="webServiceStepContainer">
                                  <Box
                                    className="webServiceStepCircle1"
                                    sx={{ height: 200, width: 200 }}
                                  >
                                    <img
                                      src={Img11}
                                      loading="lazy"
                                      class="img-fluid"
                                      alt="Step4"
                                    />
                                  </Box>
                                </Box>
                                <Typography
                                  variant="body1"  fontFamily={"open sans"}
                                  fontWeight={"900"}
                                  mt={1}
                                >
                                  Testing & Launch
                                </Typography>
                                <Typography variant="caption"  fontFamily={"open sans"} color="#252525" mt={1}>
                                  {" "}
                                  Rigorous testing is done before the final launch
                                  to ensure bug-free performance.
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>

                {/* <Grid //PRICING PLANS SECTION
                  size={{ xs: 12, md: 12 }}
                >
                  <Box className="webServiceMainContainer">
                    <Box className="webServiceSecondaryContainer">
                      <Grid container rowSpacing={2}>
                        <Grid size={{ xs: 12, md: 12 }}>
                          <Box className="webServicePricingTextContainer">
                          <Chip
                  label="PRICING ACTION"
                  className="homeChip"
                  sx={{ borderRadius: "5px", padding: "0px" }}
                />
                            <Typography
                              variant="h5"
                              fontWeight={"bold"}
                              fontFamily={"open sans"}
                              mx={0}
                              sx={{ marginBottom: "10px" }}
                            >
                              Innovating For A Better Tomorrow
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid size={{ xs: 12, md: 12 }}>
                          {" "}
                          <Box className="webServicePricingPlanContainer">
                            <Grid container>
                              <Grid size={{ xs: 12, md: 4 }}>
                                <Box className="webServicePricingLeftContainer">
                                  <Typography variant="h6" fontWeight={"bold"}>
                                    Basic Plan
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    Kickstart your journey with our 15-minute free
                                    Initial Consultation. We'll explore your
                                    business needs and identify key areas for
                                    improvement to set you on the path to success.
                                  </Typography>
                                </Box>
                              </Grid>
                              <Divider variant="fullWidth" />
                              <Grid size={{ xs: 12, md: 8 }}>
                                <Box className="webServicePricingRightContainer">
                                  <Box className="box1">
                                    <List size="sm" sx={{ padding: "0%" }}>
                                      <ListItem>
                                        <ListItemDecorator sx={{ margin: "0%" }}>
                                          <CheckCircleOutlineIcon
                                            color="error"
                                            fontSize="small"
                                          />
                                        </ListItemDecorator>
                                        Up to 5 Pages
                                      </ListItem>
                                      <ListItem>
                                        <ListItemDecorator sx={{ margin: "0%" }}>
                                          {" "}
                                          <CheckCircleOutlineIcon
                                            color="error"
                                            fontSize="small"
                                          />{" "}
                                        </ListItemDecorator>
                                        Responsive Design
                                      </ListItem>
                                      <ListItem>
                                        <ListItemDecorator sx={{ margin: "0%" }}>
                                          <CheckCircleOutlineIcon
                                            color="error"
                                            fontSize="small"
                                          />
                                        </ListItemDecorator>
                                        Basic SEO
                                      </ListItem>
                                      <ListItem>
                                        <ListItemDecorator sx={{ margin: "0%" }}>
                                          <CheckCircleOutlineIcon
                                            color="error"
                                            fontSize="small"
                                          />
                                        </ListItemDecorator>
                                        Custom UI Design
                                      </ListItem>
                                      <ListItem>
                                        <ListItemDecorator sx={{ margin: "0%" }}>
                                          <CheckCircleOutlineIcon
                                            color="error"
                                            fontSize="small"
                                          />
                                        </ListItemDecorator>
                                        1 Month Free Support
                                      </ListItem>
                                    </List>
                                  </Box>
                                  <Box className="box1">
                                    <Typography
                                      variant="h5"
                                      fontWeight={"bold"}
                                      sx={{ mr: "auto" }}
                                    >
                                      CAD $500{" "}
                                      <Typography sx={{ fontSize: "small" }}>
                                        / month
                                      </Typography>
                                    </Typography>
                                  </Box>
                                  <Box className="box1">
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      sx={{ mr: "auto" }}
                                    >
                                      {" "}
                                      Select Plan{" "}
                                    </Button>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>

                        <Grid size={{ xs: 12, md: 12 }}>
                          {" "}
                          <Box className="webServicePricingPlanContainer">
                            <Grid container>
                              <Grid size={{ xs: 12, md: 4 }}>
                                <Box className="webServicePricingLeftContainer">
                                  <Typography variant="h6" fontWeight={"bold"}>
                                    Basic Plan
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    Kickstart your journey with our 15-minute free
                                    Initial Consultation. We'll explore your
                                    business needs and identify key areas for
                                    improvement to set you on the path to success.
                                  </Typography>
                                </Box>
                              </Grid>
                              <Divider variant="fullWidth" />
                              <Grid size={{ xs: 12, md: 8 }}>
                                <Box className="webServicePricingRightContainer">
                                  <Box className="box1">
                                    <List size="sm" sx={{ padding: "0%" }}>
                                      <ListItem>
                                        <ListItemDecorator sx={{ margin: "0%" }}>
                                          <CheckCircleOutlineIcon
                                            color="error"
                                            fontSize="small"
                                          />
                                        </ListItemDecorator>
                                        Up to 5 Pages
                                      </ListItem>
                                      <ListItem>
                                        <ListItemDecorator sx={{ margin: "0%" }}>
                                          {" "}
                                          <CheckCircleOutlineIcon
                                            color="error"
                                            fontSize="small"
                                          />{" "}
                                        </ListItemDecorator>
                                        Responsive Design
                                      </ListItem>
                                      <ListItem>
                                        <ListItemDecorator sx={{ margin: "0%" }}>
                                          <CheckCircleOutlineIcon
                                            color="error"
                                            fontSize="small"
                                          />
                                        </ListItemDecorator>
                                        Basic SEO
                                      </ListItem>
                                      <ListItem>
                                        <ListItemDecorator sx={{ margin: "0%" }}>
                                          <CheckCircleOutlineIcon
                                            color="error"
                                            fontSize="small"
                                          />
                                        </ListItemDecorator>
                                        Custom UI Design
                                      </ListItem>
                                      <ListItem>
                                        <ListItemDecorator sx={{ margin: "0%" }}>
                                          <CheckCircleOutlineIcon
                                            color="error"
                                            fontSize="small"
                                          />
                                        </ListItemDecorator>
                                        1 Month Free Support
                                      </ListItem>
                                    </List>
                                  </Box>
                                  <Box className="box1">
                                    <Typography
                                      variant="h5"
                                      fontWeight={"bold"}
                                      sx={{ mr: "auto" }}
                                    >
                                      CAD $500{" "}
                                      <Typography sx={{ fontSize: "small" }}>
                                        / month
                                      </Typography>
                                    </Typography>
                                  </Box>
                                  <Box className="box1">
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      sx={{ mr: "auto" }}
                                    >
                                      {" "}
                                      Select Plan{" "}
                                    </Button>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>

                        <Grid size={{ xs: 12, md: 12 }}>
                          {" "}
                          <Box className="webServicePricingPlanContainer">
                            <Grid container>
                              <Grid size={{ xs: 12, md: 4 }}>
                                <Box className="webServicePricingLeftContainer">
                                  <Typography variant="h6" fontWeight={"bold"}>
                                    Basic Plan
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    Kickstart your journey with our 15-minute free
                                    Initial Consultation. We'll explore your
                                    business needs and identify key areas for
                                    improvement to set you on the path to success.
                                  </Typography>
                                </Box>
                              </Grid>
                              <Divider variant="fullWidth" />
                              <Grid size={{ xs: 12, md: 8 }}>
                                <Box className="webServicePricingRightContainer">
                                  <Box className="box1">
                                    <List size="sm" sx={{ padding: "0%" }}>
                                      <ListItem>
                                        <ListItemDecorator sx={{ margin: "0%" }}>
                                          <CheckCircleOutlineIcon
                                            color="error"
                                            fontSize="small"
                                          />
                                        </ListItemDecorator>
                                        Up to 5 Pages
                                      </ListItem>
                                      <ListItem>
                                        <ListItemDecorator sx={{ margin: "0%" }}>
                                          {" "}
                                          <CheckCircleOutlineIcon
                                            color="error"
                                            fontSize="small"
                                          />{" "}
                                        </ListItemDecorator>
                                        Responsive Design
                                      </ListItem>
                                      <ListItem>
                                        <ListItemDecorator sx={{ margin: "0%" }}>
                                          <CheckCircleOutlineIcon
                                            color="error"
                                            fontSize="small"
                                          />
                                        </ListItemDecorator>
                                        Basic SEO
                                      </ListItem>
                                      <ListItem>
                                        <ListItemDecorator sx={{ margin: "0%" }}>
                                          <CheckCircleOutlineIcon
                                            color="error"
                                            fontSize="small"
                                          />
                                        </ListItemDecorator>
                                        Custom UI Design
                                      </ListItem>
                                      <ListItem>
                                        <ListItemDecorator sx={{ margin: "0%" }}>
                                          <CheckCircleOutlineIcon
                                            color="error"
                                            fontSize="small"
                                          />
                                        </ListItemDecorator>
                                        1 Month Free Support
                                      </ListItem>
                                    </List>
                                  </Box>
                                  <Box className="box1">
                                    <Typography
                                      variant="h5"
                                      fontWeight={"bold"}
                                      sx={{ mr: "auto" }}
                                    >
                                      CAD $500{" "}
                                      <Typography sx={{ fontSize: "small" }}>
                                        / month
                                      </Typography>
                                    </Typography>
                                  </Box>
                                  <Box className="box1">
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      sx={{ mr: "auto" }}
                                    >
                                      {" "}
                                      Select Plan{" "}
                                    </Button>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid> */}
              {/* </Box> */}
              </Grid>
            </Grid>
        </Grid>
          </Box>
        </Box>

        <Grid //CONTACT SECTION STARTS HERE
          size={{ xs: 12, md: 12 }}
          id="contact"
          sx={{ width: "100%", backgroundColor: "none" }}
        >
          <Box className="OuterContainer">
            <Box className="InnerContainer">
              <Grid container spacing={3}>
                <Grid size={{ xs: 12, md: 12, lg: 7 }}>
                  {/* <Box className="homeContactInfo"> */}
                  <Chip
                  label="GET IN TOUCH"
                  className="homeChip"
                  sx={{ borderRadius: "5px", padding: "0px" }}
                />
                  {/* <Typography
                    variant="subtitle2"
                    fontWeight={"bold"}
                    fontFamily={"open sans"}
                    sx={{ color: "#b19478" }}
                    align="left"
                  >
                    GET IN TOUCH
                  </Typography> */}
                  <Typography
                    variant="h4"
                    align="left"
                    fontWeight={"bold"}
                    fontFamily={"open sans"}
                    color="black"
                  >
                    Reach Our Team
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    // fontWeight={"bold"}
                    fontFamily={"open sans"}
                    my={1}
                    color="black"
                  >
                    Send a message through given form, If your enquiry is time
                    sensitive please use below contact details.
                  </Typography>
                  <Box className="homeContactTimingContainer">
                    <Grid container spacing={3}>
                      <Grid size={{ xs: 12, md: 12, lg: 3 }}>
                        <Typography
                          variant="body1"
                          align="left"
                          fontWeight={"bold"}
                          fontFamily={"open sans"}
                          // mt={1}
                          color="#16939c"
                        >
                          Hours:
                        </Typography>
                      </Grid>
                      <Grid size={{ xs: 12, md: 12, lg: 9 }}>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <Box mr={5}>
                            <Typography
                              variant="subtitle2"
                              fontFamily={"open sans"}
                            >
                              Monday - Friday
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              fontFamily={"open sans"}
                            >
                              Saturday - Sunday
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant="subtitle2"
                              fontFamily={"open sans"}
                            >
                              9AM - 5PM (EST)
                            </Typography>

                            <Typography
                              variant="subtitle2"
                              fontFamily={"open sans"}
                            >
                              Closed
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className="homeContactTimingContainer">
                    <Grid container spacing={3}>
                      <Grid size={{ xs: 12, md: 12, lg: 3 }}>
                        <Typography
                          variant="body1"
                          align="left"
                          fontWeight={"bold"}
                          fontFamily={"open sans"}
                          // mt={1}
                          color="#16939c"
                        >
                          Get In Touch:
                        </Typography>
                      </Grid>
                      <Grid size={{ xs: 12, md: 12, lg: 9 }}>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <Box mr={7}>
                            <Typography
                              variant="subtitle2"
                              fontFamily={"open sans"}
                            >
                              {" "}
                              (343)-254-4596
                            </Typography>
                            {/* <Typography variant="subtitle2" fontFamily={'open sans'}>Saturday-Sunday</Typography> */}
                          </Box>
                          <Box>
                            <Typography
                              variant="subtitle2"
                              fontFamily={"open sans"}
                            >
                              sales@clovvista.com
                            </Typography>
                            {/* <Typography variant="subtitle2" fontFamily={'open sans'}>Closed</Typography> */}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  {/* </Box> */}
                  {/* <Typography
                    variant="h4"
                    align="left"
                    fontWeight={"bold"}
                    fontFamily={"open sans"}
                    color="black"
                  >
                    Drop Us A Line!
                  </Typography> */}
                  {/* <ContactForm/> */}
                </Grid>

                <Grid size={{ xs: 12, md: 12, lg: 5 }}>
                  {/* <img src={Image} class="img-fluid" alt="logo"/> */}
                  <Typography
                    variant="h6"
                    align="left"
                    fontWeight={"bold"}
                    fontFamily={"open sans"}
                    color="black"
                  >
                    Drop Us A Line!
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    // fontWeight={"bold"}
                    fontFamily={"open sans"}
                    my={1}
                    color="gray"
                  >
                    Feel free to send us a message about anything you might need
                    help with. We would love to hear from you
                  </Typography>
                  <ContactForm />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>

        <Grid //FOOTER SECTION STARTS HERE
          size={{ xs: 12, md: 12 }}
          sx={{ width: "100%", backgroundColor: "none" }}
        >
          <Footer />
        </Grid>
      </Grid>
    </Fragment>
  );
}
