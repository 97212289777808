import React, { Fragment, useEffect } from "react";
import "./ServiceStyles.css";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import ContactForm from "../../Components/ContactForm/ContactForm";
import Grid from "@mui/material/Grid2";
import { Box, Typography, Chip } from "@mui/material";
import {
  List,
  ListItem,
  ListItemDecorator,
  ListItemContent,
  ListItemButton,
  ListDivider,
} from "@mui/joy";
import HeroImg from "../../Assets/HeroImg.png";
import Img1 from "../../Assets/cloud service.png";
import Img2 from "../../Assets/Rectangle 60.png";
import Img3 from "../../Assets/Group 9.png";
import Img4 from "../../Assets/Group 10.png";
import Img5 from "../../Assets/Group 11.png";
import Img6 from "../../Assets/Group 12.png";
import Img7 from "../../Assets/Group 13.png";
import Icon1 from "../../Assets/coding.png";
import Icon2 from "../../Assets/web-design.png"
import Icon3 from "../../Assets/network-security.png"
import Icon4 from "../../Assets/server.png"
import { useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

const reasons = [
  {
    id: 1,
    label: "Scalability",
    description:
      "Easily scale your infrastructure up or down as your business grows, ensuring optimal performance at all times.",
    icon: Img3,
  },
  {
    id: 2,
    label: "Cost Efficiency",
    description:
      "Pay only for what you use with our cloud models, saving costs on physical hardware and maintenance.",
    icon: Img4,
  },
  {
    id: 3,
    label: "Security & Compliance",
    description:
      "We ensure your data is secure and compliant with industry standards, such as GDPR and HIPAA.",
    icon: Img5,
  },
  {
    id: 4,
    label: "Automation",
    description:
      "Our DevOps services automate repetitive tasks, making your workflows more efficient.",
    icon: Img6,
  },
  {
    id: 5,
    label: "Disaster Recovery",
    description:
      "We set up robust disaster recovery plans to protect your business from unexpected data loss.",
    icon: Img7,
  },
];

export default function Services() {

  const navigate = useNavigate()

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return (
    <Fragment>
      <Navbar />

      <Grid container>
        <Grid //HERO SECTION
          size={{ xs: 12, md: 12 }}
          id="web-hero-section"
          sx={{ backgroundColor: "hsla(0, 0%, 0%, 0.521)" }}
        >
          <Box className="webServiceHeroContainer">
            <Box className="webServiceOverlay">
              <Box className="webServiceHeroImgContainer">
                <img
                  src={HeroImg}
                  height={300}
                  width={"100%"}
                  alt="backgroundImage"
                />
              </Box>
              <Box className="webServiceHeroContentContainer">
                <Box className="webServiceHeroContentInnerContainer">
                  <Fade cascade triggerOnce="true">
                    <Typography
                      variant="h3"
                      fontWeight={"bold"}
                      fontFamily={"open sans"}
                      mt={1}
                      color="white"
                      // sx={{ fontSize: { lg: 66, md: 50, xs: 15 } }}
                    >
                      Cloud Infrastructure & Solutions
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      fontFamily={"open sans"}
                      mt={2}
                      mx={1}
                      color="white"
                      // sx={{ fontSize: { sm: 15, xs: 10 } }}
                    >
                      Empowering Growth with Scalable Cloud Solutions
                    </Typography>
                  </Fade>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Box
          sx={{
            // backgroundColor: "red",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "85%", maxWidth: "1536px" }}>
            <Grid container spacing={2}>
              <Grid
                size={{ xs: 12, lg: 3 }}
                sx={{
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#f7f8f9",
                    width: "100%",
                    padding: "4% 2%",
                    mt: "2rem",
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight={"bold"}
                    fontFamily={"open sans"}
                    mx={0}
                    sx={{ marginBottom: "0px" }}
                  >
                    Services
                  </Typography>
                  <List
                    inset="gutter"
                    size="sm"
                    sx={{
                      "--ListItem-paddingY": "10px",
                    }}
                  >
                    <ListItem>
                      <ListItemButton color="neutral" onClick={() => { navigate('/web-development') }}>
                        <ListItemDecorator sx={{ margin: "0px" }}>
                        <img src={Icon1} height={30} width={30} alt="icon1" />
                        </ListItemDecorator>
                        <ListItemContent fon>Web Development</ListItemContent>
                      </ListItemButton>
                    </ListItem>
                    <ListDivider />
                    <ListItem>
                      <ListItemButton color="neutral" onClick={() => { navigate('/uiux-designing') }}>
                        <ListItemDecorator sx={{ margin: "0px" }}>
                        <img src={Icon2} height={30} width={30} alt="icon1" />
                        </ListItemDecorator>
                        <ListItemContent>UI/UX Designing</ListItemContent>
                      </ListItemButton>
                    </ListItem>
                    <ListDivider />
                    <ListItem>
                      <ListItemButton color="neutral" onClick={() => { navigate('/cloud-solution') }}>
                        <ListItemDecorator sx={{ margin: "0px" }}>
                        <img src={Icon4} height={30} width={30} alt="icon1" />
                        </ListItemDecorator>
                        <ListItemContent>
                          Cloud and Solutions Architecture
                        </ListItemContent>
                      </ListItemButton>
                    </ListItem>
                    <ListDivider />
                    <ListItem>
                      <ListItemButton color="neutral" onClick={() => { navigate('/network-solution') }}>
                        <ListItemDecorator sx={{ margin: "0px" }}>
                        <img src={Icon3} height={30} width={30} alt="icon1" />
                        </ListItemDecorator>
                        <ListItemContent>
                          IT & Networking Solutions
                        </ListItemContent>
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Box>
              </Grid>
              <Grid size={{ xs: 12, lg: 9 }}>
                <Grid container>
                  <Grid //INTRO SECTION
                    size={{ xs: 12, md: 12 }}
                  >
                    <Box className="webServiceMainContainer">
                      <Box className="webServiceSecondaryContainer">
                        <Grid container>
                          <Grid size={{ xs: 12, md: 12 }}>
                            <Box className="webServiceIntroImageContainer">
                              <img
                                src={Img1}
                                loading="lazy"
                                alt="design"
                                className="webServiceIntroImage"
                              />
                            </Box>
                          </Grid>
                          <Grid size={{ xs: 12, md: 12 }}>
                            <Box className="webServiceIntroTextContainer">
                            <Chip
                  label="UNLOCK THE POWER OF CLOUD"
                  className="homeChip"
                  sx={{ borderRadius: "5px", padding: "4px 0px 4px 0px", height: 'auto',
                    '& .MuiChip-label': {
                      display: 'block',
                      whiteSpace: 'normal',
                    },}}
                />
                              <Typography
                                variant="h5"
                                fontWeight={"bold"}
                                fontFamily={"open sans"}
                                mx={0}
                                align="left"
                                sx={{ marginBottom: "10px" }}
                              >
                                Unlock the Power of the Cloud for Scalable, Secure, and Agile Business Solutions
                              </Typography>
                              <Typography
                                variant="body2"
                                fontFamily={"open sans"}
                                color="#252525"
                                align="left"
                              >
Cloud computing is the backbone of modern businesses, enabling agility, scalability, and innovation. At ClovVista Consulting, we specialize in cloud infrastructure design, deployment, and management to help your business scale with confidence. 
</Typography>
                              <Typography
                                variant="body2"
                                fontFamily={"open sans"}
                                color="#252525"
                                align="left"
                                mt={2}
                              >
We provide end-to-end solutions using major platforms like AWS, Azure, and Google Cloud to reduce operational costs while enhancing productivity.                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid //WHY CHOOSE US SECTION
                    size={{ xs: 12, md: 12 }}
                  >
                    <Box className="webServiceMainContainer" sx={{backgroundColor:'#e7f5ef'}}>
                      <Box className="webServiceSecondaryContainer" >
                        <Grid container>
                        <Grid size={{ xs: 12, md: 12 }} >
                          <Chip
                              label="WHY CHOOSE US"
                              className="homeChip"
                              sx={{ borderRadius: "5px", padding: "0px" }}
                            />
                              <Typography
                                variant="h5"
                                fontWeight={"bold"}
                                fontFamily={"open sans"}
                                mx={0}
                                sx={{ marginBottom: "0px" }}
                              >
                                Benefits of Our Cloud Solutions
                              </Typography>
                        </Grid>
                          <Grid size={{ xs: 12, md: 7 }}>
                            <Box className="webServiceChooseContainer">
                            
                              <Box className="webServiceLeftContainer">
                                {reasons.map((index) => {
                                  return (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        alignItems: "flex-start",
                                        marginTop: "1rem",
                                      }}
                                    >
                                      <img
                                        src={index.icon}
                                        height={30}
                                        width={30}
                                        alt='point icons'
                                        loading="lazy"
                                      />
                                      <Typography
                                        variant="h6"
                                        mx={3}
                                        fontWeight={"bold"}
                                      >
                                        {index.label}
                                        <Typography variant="subtitle2">
                                          {index.description}
                                        </Typography>
                                      </Typography>
                                    </Box>
                                  );
                                })}
                              </Box>
                            </Box>
                          </Grid>
                          <Grid size={{ xs: 12, md: 5 }}>
                            <Box className="webServiceRightContainer">
                              <img
                                src={Img2}
                                loading="lazy"
                                class="img-fluid"
                                alt="backgroundImage"
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Grid //CONTACT SECTION STARTS HERE
          size={{ xs: 12, md: 12 }}
          id="contact"
          sx={{ width: "100%", backgroundColor: "none" }}
        >
          <Box className="OuterContainer">
            <Box className="InnerContainer">
              <Grid container spacing={3}>
                <Grid size={{ xs: 12, md: 12, lg: 7 }}>
                  {/* <Box className="homeContactInfo"> */}
                  {/* <Typography
                    variant="subtitle2"
                    fontWeight={"bold"}
                    fontFamily={"open sans"}
                    sx={{ color: "#b19478" }}
                    align="left"
                  >
                    GET IN TOUCH
                  </Typography> */}
                   <Chip
                  label="GET IN TOUCH"
                  className="homeChip"
                  sx={{ borderRadius: "5px", padding: "0px" }}
                />
                  <Typography
                    variant="h4"
                    align="left"
                    fontWeight={"bold"}
                    fontFamily={"open sans"}
                    color="black"
                  >
                    Reach Our Team
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    // fontWeight={"bold"}
                    fontFamily={"open sans"}
                    my={1}
                    color="black"
                  >
                    Send a message through given form, If your enquiry is time
                    sensitive please use below contact details.
                  </Typography>
                  <Box className="homeContactTimingContainer">
                    <Grid container spacing={3}>
                      <Grid size={{ xs: 12, md: 12, lg: 3 }}>
                        <Typography
                          variant="body1"
                          align="left"
                          fontWeight={"bold"}
                          fontFamily={"open sans"}
                          // mt={1}
                          color="#16939c"
                        >
                          Hours:
                        </Typography>
                      </Grid>
                      <Grid size={{ xs: 12, md: 12, lg: 9 }}>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <Box mr={5}>
                            <Typography
                              variant="subtitle2"
                              fontFamily={"open sans"}
                            >
                              Monday - Friday
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              fontFamily={"open sans"}
                            >
                              Saturday - Sunday
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant="subtitle2"
                              fontFamily={"open sans"}
                            >
                              9AM - 5PM (EST)
                            </Typography>

                            <Typography
                              variant="subtitle2"
                              fontFamily={"open sans"}
                            >
                              Closed
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className="homeContactTimingContainer">
                    <Grid container spacing={3}>
                      <Grid size={{ xs: 12, md: 12, lg: 3 }}>
                        <Typography
                          variant="body1"
                          align="left"
                          fontWeight={"bold"}
                          fontFamily={"open sans"}
                          // mt={1}
                          color="#16939c"
                        >
                          Get In Touch:
                        </Typography>
                      </Grid>
                      <Grid size={{ xs: 12, md: 12, lg: 9 }}>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <Box mr={7}>
                            <Typography
                              variant="subtitle2"
                              fontFamily={"open sans"}
                            >
                              {" "}
                              (343)-254-4596
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant="subtitle2"
                              fontFamily={"open sans"}
                            >
                              sales@clovvista.com
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Grid size={{ xs: 12, md: 12, lg: 5 }}>
                  <Typography
                    variant="h6"
                    align="left"
                    fontWeight={"bold"}
                    fontFamily={"open sans"}
                    color="black"
                  >
                    Drop Us A Line!
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    // fontWeight={"bold"}
                    fontFamily={"open sans"}
                    my={1}
                    color="gray"
                  >
                    Feel free to send us a message about anything you might need
                    help with. We would love to hear from you
                  </Typography>
                  <ContactForm />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>

        <Grid //FOOTER SECTION STARTS HERE
          size={{ xs: 12, md: 12 }}
          sx={{ width: "100%", backgroundColor: "none" }}
        >
          <Footer />
        </Grid>
      </Grid>
    </Fragment>
  );
}
