import React, { Fragment } from "react";
import "./ProjectList.css";
import { Box, Typography, Chip } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Navbar from "../../Components/Navbar/Navbar";
import ProjectCards from "../../Components/ProjectCards/ProjectCards";
import CountUp from "react-countup";
import { Fade } from "react-awesome-reveal";
import Img from '../../Assets/Customer Insight.png'

export default function ProjectList() {
  const [num, setNum] = React.useState(20);

  return (
    <Fragment>
      <Navbar />

      <Grid container>
        <Grid
          size={{ xs: 12, md: 12, lg: 12 }}
          sx={{ width: "100%", backgroundColor: "pink" }}
        >
          <Box className="ProjectListOuterContainer">
            <Box className="ProjectListInnerContainer">
              <Fade triggerOnce="true">
                <Grid container>
                  <Grid
                    size={{ xs: 12, md: 12, lg: 12 }}
                    sx={{ padding: "2rem", backgroundColor: "pink" }}
                  />
                  <Grid size={{ xs: 12, md: 12, lg: 7 }}>
                    <Chip
                      label="CASE STUDIES"
                      className="homeChip"
                      sx={{ borderRadius: "5px", padding: "0px" }}
                    />
                    <Typography
                      variant="h4"
                      fontWeight={"bold"}
                      fontFamily={"open sans"}
                      mt={1}
                      // mb={4}
                      sx={{ color: "black" }}
                      // align="center"
                    >
                      Have a view of our amazing projects with our clients
                    </Typography>
                  </Grid>
                </Grid>
              </Fade>
            </Box>
          </Box>
        </Grid>

        <Grid
          size={{ xs: 12, md: 12, lg: 12 }}
          sx={{ width: "100%", backgroundColor: "red" }}
        >
          <Box className="ProjectListOuterContainer">
            <Box className="ProjectListInnerContainer">
              <Fade triggerOnce="true">
                <Box sx={{ width: "100%", padding: "0% 5px" }}>
                  <ProjectCards />
                </Box>
              </Fade>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid size={{ xs: 12, md: 12, lg: 12 }}>
        <Box className="ProjectListOuterContainer">
          <Box className="ProjectListInnerContainer">
            <Grid container spacing={2}>
              <Grid
                size={{ xs: 12, md: 6, lg: 3 }}
                sx={{
                  backgroundColor: "orange",
                  padding: "3%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "pink",
                    display: "flex",
                    justifyContent: "center",
                    alignItems:'center',
                    flexDirection: "column",
                  }}
                >
                  <CountUp className="projectNumberStyle"
                    start={0}
                    end={20}
                    duration={2}
                    // separator=" "
                    suffix="+"
                  />
                  <Typography
                    variant="subtitle1"
                    fontWeight={"bold"}
                    color="grey"
                    align="center"
                  >
                    Clients Around The World
                  </Typography>
                </Box>
              </Grid>
              <Grid
                size={{ xs: 12, md: 6, lg: 3 }}
                sx={{ backgroundColor: "orange", padding: "3%" }}
              >
                <Box
                  sx={{
                    backgroundColor: "pink",
                    display: "flex",
                    justifyContent: "center",
                    alignItems:'center',
                    flexDirection: "column",
                  }}
                >
                  <CountUp className="projectNumberStyle"
                    start={0}
                    end={20}
                    duration={2}
                    // separator=" "
                    suffix="+"
                  />
                  <Typography
                    variant="subtitle1"
                    fontWeight={"bold"}
                    color="grey"
                    align="center"
                  >
                    Successful Projects
                  </Typography>
                </Box>
              </Grid>
              <Grid
                size={{ xs: 12, md: 6, lg: 3 }}
                sx={{ backgroundColor: "orange", padding: "3%" }}
              >
                  <Box
                  sx={{
                    backgroundColor: "pink",
                    display: "flex",
                    justifyContent: "center",
                    alignItems:'center',
                    flexDirection: "column",
                  }}
                >
                  <CountUp className="projectNumberStyle"
                    start={0}
                    end={20}
                    duration={2}
                    // separator=" "
                    suffix="+"
                  />
                  <Typography
                  variant="subtitle1"
                  fontWeight={"bold"}
                  color="grey"
                >
                  Business Growth
                </Typography>
                </Box>
              </Grid>
              <Grid
                size={{ xs: 12, md: 6, lg: 3 }}
                sx={{ backgroundColor: "orange", padding: "3%" }}
              >
                <Box
                  sx={{
                    backgroundColor: "pink",
                    display: "flex",
                    justifyContent: "center",
                    alignItems:'center',
                    flexDirection: "column",
                  }}
                >
                  <CountUp className="projectNumberStyle"
                    start={0}
                    end={20}
                    duration={2}
                    // separator=" "
                    suffix="+"
                  />
                  <Typography
                  variant="subtitle1"
                  fontWeight={"bold"}
                  color="grey"
                >
                  Team Members
                </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>

      <Grid size={{ xs: 12, md: 12, lg: 12 }}>
        <Box className="ProjectListOuterContainer">
          <Box className="ProjectListInnerContainer">
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, md: 12, lg: 6 }}>
                <img
                src={Img}
                                class="img-fluid"
                                height="500"
                                width="500"
                                alt="Our Approach"
                                loading="lazy"/>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Fragment>
  );
}
